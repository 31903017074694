import '@activesg/common/utilities/big-int/register.js'
import '@fontsource/ibm-plex-mono'
import 'inter-ui/inter.css'

import type { AppProps, AppType } from 'next/app'

import Head from 'next/head'

import { Flex, Skeleton, Stack } from '@chakra-ui/react'
import { GrowthBook } from '@growthbook/growthbook-react'
import {
  RestrictedGovtMasthead,
  ThemeProvider,
} from '@opengovsg/design-system-react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { growthbookOpts, type AppFeatures } from '@activesg/common/libraries'
import { env } from '@activesg/env'
import {
  AppBanner,
  AppVersionModalBanner,
  EnvProvider,
  GrowthbookProvider,
  LoginStateProvider,
  Suspense,
} from '@activesg/ui/components'
import { initMonitoring, ToastContainer } from '@activesg/ui/libraries'
import { type NextPageWithLayout } from '@activesg/ui/types'

import { ErrorBoundary } from '~/components/ErrorBoundary'
import { DefaultLayout } from '~/templates/layouts/DefaultLayout'
import { adminTheme } from '~/theme'
import { trpc } from '~/utils/trpc'

type AppPropsWithAuthAndLayout = AppProps & {
  Component: NextPageWithLayout
}

// We use process.env instead of env since we will capture this at build time.
if (process.env.NODE_ENV === 'production') {
  initMonitoring({ service: 'activesg-admin' })
}

const gb = new GrowthBook<AppFeatures>(growthbookOpts)

void gb.init({
  streaming: true,
})

const MyApp = ((props: AppPropsWithAuthAndLayout) => {
  return (
    <>
      <Head>
        <title>{env.NEXT_PUBLIC_APP_NAME}</title>
        <link href="/favicon.ico" rel="icon" />
        <link
          href="/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          href="/favicon-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link
          href="/android-chrome-192x192.png"
          rel="icon"
          sizes="192x192"
          type="image/png"
        />
        <link
          href="/android-chrome-512x512.png"
          rel="icon"
          sizes="512x512"
          type="image/png"
        />
        <link
          href="/apple-touch-icon.png"
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <meta content="noindex,nofollow" name="robots" />
      </Head>
      <EnvProvider env={env}>
        <LoginStateProvider>
          <GrowthbookProvider gb={gb} profile="ADMIN">
            <ThemeProvider theme={adminTheme}>
              <ErrorBoundary>
                <Suspense fallback={<Skeleton height="$100vh" width="100vw" />}>
                  <Stack height="$100vh" overflow="hidden" spacing={0}>
                    <Flex direction="column" flex={0}>
                      <RestrictedGovtMasthead />
                      <AppVersionModalBanner />
                      <AppBanner />
                    </Flex>
                    <ChildWithLayout {...props} />
                    {process.env.NODE_ENV !== 'production' && (
                      <ReactQueryDevtools initialIsOpen={false} />
                    )}
                  </Stack>
                </Suspense>
              </ErrorBoundary>
            </ThemeProvider>
          </GrowthbookProvider>
        </LoginStateProvider>
      </EnvProvider>
      <ToastContainer />
    </>
  )
}) as AppType

// This is needed so suspense will be triggered for anything within the LayoutComponents which uses useSuspenseQuery
const ChildWithLayout = ({
  Component,
  pageProps,
}: AppPropsWithAuthAndLayout) => {
  const getLayout =
    Component.getLayout ?? ((page) => <DefaultLayout>{page}</DefaultLayout>)

  return <>{getLayout(<Component {...pageProps} />)}</>
}

export default trpc.withTRPC(MyApp)
